<template>
  <div class="tag-list">
    <tag
      v-for="tag in tagData"
      :key="tag.id"
    >
      {{ tag.name }}
    </tag>
  </div>
</template>

<script>
import Tag from './Tag.vue'

export default {
  name: 'TagList',
  components: {
    Tag
  },
  props: {
    tagData: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-list {
  text-align: left;
}

.tag {
  margin: 0 17px 6px 0;
}
</style>
