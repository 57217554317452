<template>
  <div ref="mediaContainer">
    <div
      :class="mediaClasses"
    >
      <cld-image
        v-if="isSetImage"
        cloud-name="relevant"
        :public-id="mediaPublicId"
        :width="mediaWidth"
        crop="scale"
        secure="true"
      >
        <cld-transformation radius="8" />
      </cld-image>
      <div
        v-if="isSetVideo"
        @click="showVideo"
      >
        <cld-video
          cloud-name="relevant"
          :public-id="mediaPublicId"
          :width="mediaWidth"
          :controls="!isOption"
          :class="type"
          secure="true"
        />
      </div>
    </div>

    <b-modal
      v-model="isVideoModalActive"
      :can-cancel="true"
    >
      <cld-video
        cloud-name="relevant"
        :public-id="mediaPublicId"
        controls="true"
      />
    </b-modal>
  </div>
</template>

<script>
import { CldImage, CldTransformation, CldVideo } from 'cloudinary-vue'

export default {
  name: 'Media',
  components: {
    CldImage,
    CldTransformation,
    CldVideo
  },
  props: {
    mediaWidth: {
      type: Number,
      required: true
    },
    model: {
      type: Object,
      default: null
    },

    type: {
      type: String,
      default: 'question'
    }
  },
  data() {
    return {
      isVideoModalActive: false
    }
  },
  computed: {
    hasMediaFile() {
      return Boolean(this.model.mediaFile)
    },
    isSetImage() {
      return this.hasMediaFile && this.model.mediaFile.resourceType === 'image'
    },
    isSetVideo() {
      return this.hasMediaFile && this.model.mediaFile.resourceType === 'video'
    },
    isOption() {
      return this.type === 'option'
    },
    mediaPublicId() {
      return this.hasMediaFile ? this.model.mediaFile.publicId : ''
    },
    mediaClasses() {
      let classes = `media-container ${this.type}`

      if (this.hasMediaFile) {
        classes += ' media-loaded'
      }

      return classes
    }
  },
  methods: {
    showVideo() {
      if (this.isOption) {
        this.isVideoModalActive = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.media-container {
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;

  &.option {
    margin: 0px;

    &.media-loaded {
      min-width: 60px;
      width: 60px;
      height: auto;
      max-height: 60px;
      border-radius: 8px;
      background: none;
    }

    img{
      position: absolute;
      top: -9999px;
      left: -9999px;
      right: -9999px;
      bottom: -9999px;
      margin: auto;
    }
  }

  .cld-image {
    text-align: center;
  }

  .cld-video {
    text-align: center;
    display: flex;
    justify-content: center;
    background: white;

    &.option {
      height: 60px;
      cursor:pointer;
    }
  }
}
</style>

<style lang="scss">
.media-container {

  img {
    border-radius: 8px;
    background-color: white;
  }
}

.modal-close {
  top: 80px;
}
</style>
