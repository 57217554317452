<template>
  <div
    class="avatar"
    :style="componentStyle"
  >
    {{ userInitials }}
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    userData: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: 50
    }
  },
  computed: {
    userFullName() {
      return `${this.userData.firstName} ${this.userData.lastName}`
    },
    avatarColor() {
      const hash = this.hashCode(this.userFullName)
      const color = this.intToRGB(hash)

      return color
    },
    avatarFontColor() {
      return `#${this.avatarColor}cc`
    },
    avatarBackgroundColor() {
      return `#${this.avatarColor}22`
    },
    userInitials() {
      const { firstName, lastName } = this.userData

      const firstNameInitial = firstName && firstName !== '' ? firstName.charAt(0) : 'N'
      const lastNameInitial = lastName && lastName !== '' ? lastName.charAt(0) : 'N'

      return `${firstNameInitial}${lastNameInitial}`
    },
    componentStyle() {
      const size = `${this.size}px`

      return {
        width: size,
        height: size,
        color: this.avatarFontColor,
        'font-size': `${(2 / 5) * this.size}px`,
        'background-color': this.avatarBackgroundColor
      }
    }
  },
  methods: {
    hashCode(str) {
      let hash = 0

      for (let i = 0; i < str.length; i += 1) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash) // eslint-disable-line no-bitwise
      }

      return hash
    },
    intToRGB(i) {
      const c = (i & 0x00FFFFFF) // eslint-disable-line no-bitwise
        .toString(16)
        .toUpperCase()

      return '00000'.substring(0, 6 - c.length) + c
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  background-color: #ffeaea;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #f89696;
  text-transform: uppercase;
  user-select: none;
  flex-shrink: 0;
}
</style>
