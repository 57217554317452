<template>
  <span class="tag">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'Tag'
}
</script>

<style lang="scss" scoped>
.tag {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  color: #1bae52;
  line-height: 24px;
  white-space: nowrap;
  background: #3ecf7412;
  user-select: none;
}
</style>
